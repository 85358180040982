import { BlockListItem, ContentPageSuffix, ErrorMessage, Image, Usp } from './generalCmsData';

export enum CancelAppliesTo {
  ExpiresNextMonth = 'EXPIRES_NEXT_MONTH',
  HasSavedSurf = 'HAS_SAVED_SURF',
  IsCancellation = 'IS_CANCELLATION',
  IsMainSubscription = 'IS_MAIN_SUBSCRIPTION',
  IsRegret = 'IS_REGRET',
  IsVoice = 'IS_VOICE',
}

export type PriceTableRow = {
  priceRowDescription: string;
  priceRowPrice: string;
  priceRowShouldHighlight: boolean;
};

export enum OptionAppliesTo {
  Both = 'Both',
  Voice = 'Voice',
  MBB = 'MBB',
}

export type OptionItem = {
  isOutsideMyPages: boolean;
  nextStepReference: string;
  optionAppliesTo: OptionAppliesTo;
  optionAppliesToNonContract: boolean;
  optionDescription: string;
  optionUrl?: string;
  trackAsExitLink: boolean;
};

export type SubmitItem = {
  clauseAppliesTo: CancelAppliesTo;
  clauseDescription: string;
};

export type FormItem = {
  choiceDescription: string;
  choiceValue: string;
};

export enum FunnelStepType {
  FunnelOptionStepBlock = 'FunnelOptionStepBlock',
  FunnelFormStepBlock = 'FunnelFormStepBlock',
  FunnelSubmitStepBlock = 'FunnelSubmitStepBlock',
}

export type StepItem = BlockListItem<OptionItem | FormItem | SubmitItem>;

export type FunnelStepBase = {
  $type: FunnelStepType;
  stepBody?: string;
  stepExitLinkText: string;
  stepExitLinkUrl: string;
  stepHeader: string;
  stepItems: StepItem[];
  stepItemsHeader?: string;
  stepNumber: number;
  stepReference: string;
};

export type OptionStep = FunnelStepBase & {
  stepOptionsHeader: string;
  stepPriceTableFineprint?: string;
  stepPriceTableHeader: string;
  stepPriceTablePriceRows?: BlockListItem<PriceTableRow>[];
  uspList?: BlockListItem<Usp>[];
};

export type FormStep = FunnelStepBase & {
  nextStepReference: string;
  stepButtonText: string;
  stepOtherChoiceDescription: string;
  stepShowTextArea: boolean;
  stepTextAreaHint: string;
};

export type SubmitStep = FunnelStepBase & {
  stepConfirmText: string;
  stepButtonText: string;
};

export type FunnelStep = OptionStep | FormStep | SubmitStep;

export type CancelSubscriptionCmsData = ContentPageSuffix & {
  b2bOnlyBody: string;
  b2bStep1Reference: string;
  b2cOnlyBody: string;
  b2cStep1Reference: string;
  backToSubscriptionLinkText: string;
  cancellationSubheader: string;
  changeStepLinkText: string;
  confirmationHeader: string;
  confirmationImage: Image;
  confirmationLinkText: string;
  funnelErrors: BlockListItem<ErrorMessage<string>>[];
  funnelHeader: string;
  funnelSteps: BlockListItem<FunnelStep>[];
  funnelUsps: BlockListItem<Usp>[];
  multiSubscriptionBody: string;
  oneSubscriptionBody: string;
  regretSubheader: string;
  step2HeaderPlaceholder: string;
  step3Reference: string;
};
