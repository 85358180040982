import { Product } from '../product';
import {
  ActionPageInformationBlock,
  BlockListItem,
  ContentPageSuffix,
  FaqBlock,
  Modal,
  OfferListItem,
  Usp,
} from './generalCmsData';
import { ErrorMessageWrappedInContent } from '../error';

export enum BindingErrors {
  CLIENT_ERROR_IS_BINDING_PERIOD = 'CLIENT_ERROR_IS_BINDING_PERIOD',
}

export const OfferListReference = {
  Default: 'DEFAULT',
};

export enum BindAbonnemangCmsErrorReference {
  BANKID_REQUIRED = 'BIND_BANKID_REQUIRED',
  EXTRA_USER_NOT_ALLOWED = 'BIND_EXTRA_USER_NOT_ALLOWED',
  CASHCARD_NOT_ALLOWED = 'BIND_CASHCARD_NOT_ALLOWED',
  PENDING_CANCELLATION = 'BIND_SUBSCRIPTION_PENDING_CANCELLATION',
  STATUS_PENDING_CANCELLATION = 'BIND_STATUS_PENDING_CANCELLATION',
  STATUS_INITIAL = 'BIND_STATUS_INITIAL',
  ACTIVE_PORTOUT = 'BIND_ACTIVE_PORTOUT',
  FAIL_OTHER_REASON = 'BIND_FAIL_OTHER_REASON',
  NOT_MATCHING_OFFER = 'BIND_NOT_MATCHING_OFFER',
  B2B_NOT_ALLOWED = 'BIND_B2B_NOT_ALLOWED',
  MBB_NOT_ALLOWED = 'BIND_MBB_NOT_ALLOWED',
  IS_WITHIN_WITHDRAWAL = 'BIND_IS_WITHIN_WITHDRAWAL',
  SUSPENSION_NOT_ALLOWED = 'BIND_SUSPENSION_NOT_ALLOWED',
  GENERIC = 'GENERIC',
}

export type BindingOfferListBlock = {
  offerList: {
    id: string;
    name: string;
    offers: BlockListItem<OfferListItem>[];
  };
};

export type BindingOfferList = {
  offerList: {
    id: string;
    name: string;
    offers: BlockListItem<OfferListItem>[];
  };
  segmentReference: string;
};

export type InformationWithBinding = {
  iconUsps: BlockListItem<Usp>[];
} & ActionPageInformationBlock;

export type BindAbonnemangCmsData = {
  metaData: {
    products: string[];
    productData: Product[];
  };
  $type: 'BindingPeriodLandingPage';
  backToSubscriptionLinkText: string;
  buttonText: string;
  costSummaryLinkText: string;
  discountedPrice: string;
  errors: ErrorMessageWrappedInContent<BindAbonnemangCmsErrorReference>[];
  faq: BlockListItem<FaqBlock>[];
  headerWithBinding: string;
  headerWithoutBinding: string;
  informationWithBinding: BlockListItem<InformationWithBinding>[];
  informationWithoutBinding: BlockListItem<InformationWithBinding>[];
  productData: Product[];
  regularPrice: string;
  segmentOffers: BlockListItem<BindingOfferList>[];
  successModal: BlockListItem<Modal>[];
  terms: string;
  warning: string;
} & ContentPageSuffix;
