import { BlockListItem, Link } from './cms/generalCmsData';
import { NumberPort } from './numberPort';

export enum SubscriptionType {
  Cashcard = 'Cashcard',
  Broadband = 'Broadband',
  BroadbandCashcard = 'BroadbandCashcard',
  Mobile = 'Mobile',
  Unknown = 'Unknown',
}
export enum SubscriptionStatus {
  Initial = 'Initial',
  Active = 'Active',
  CancellationPending = 'CancellationPending',
  Terminated = 'Terminated',
  Unknown = 'Unknown',
}

export enum SubscriptionSuspensionStatus {
  Active = 'Active',
  Suspended = 'Suspended',
  PendingSuspension = 'PendingSuspension',
}

export type SubscriptionSuspension = {
  isSuspensionAllowed: boolean;
  reason?: string;
  status: SubscriptionSuspensionStatus;
  suspensionAllowedFromDate?: string;
  suspensionCostStartDate?: string;
  suspensionDate?: string;
  currentSuspensionCost?: number;
  futureSuspensionCost?: number;
};

export type SubscriptionSuspensionCost = {
  lastDayOfMonth: number;
  orderDay: number;
  payNow: number;
  payNowFormatted: string;
  payNowVat: number;
  payNowVatFormatted: string;
  recurringPrice: number;
  recurringPriceFormatted: string;
  recurringVat: number;
  recurringVatFormatted: string;
};

export enum SubscriptionStatusCode {
  Initial = 1,
  Active = 2,
  CancellationPending = 3,
  Terminated = 4,
  Unknown = 999,
}

export enum ConnectHardwareEligabilityStatus {
  Allowed = 'Allowed',
  NonContractNotAllowed = 'NonContractNotAllowed',
  HardwareAlreadyConnected = 'HardwareAlreadyConnected',
}

export type Usage = {
  validTo: string;
  validFrom: string;
  currentPackageDataTotal: number;
  dataTotal: number;
  dataUsed: number;
  dataRemaining: number;
  euLimitTotal: number;
  euLimitRemaining: number;
  hasEuLimitBeenConsumed: boolean;
  rollOverDataRemaining: number; // saved surf
  rollOverDataTotal: number;
  selfManagedDataLimitRemaining?: number;
  selfManagedDataLimitTotal?: number;
  selfManagedDataLimitUsed?: number;
};

export type DiscountVoucher = {
  name: string;
  price: string;
  ordinaryPrice: string;
  description: string;
  validFrom: string;
  validTo: string;
  isBinding?: boolean;
  isInWithdrawalPeriod?: boolean;
};

// These value are set in backend when a subscription
// doesn't have an active plan eg: "Kontantkort 4GB",
// so the name will simply be one of the below
export enum NonRecurrentStatus {
  NonRecurrentName = 'Kontantkort',
  NonRecurrentMbbName = 'Mobilt Bredband Kontantkort',
}

export enum SimStatus {
  OpenAndActive = 'OpenAndActive',
  InUse = 'InUse',
  Reserved = 'Reserved',
  RestPeriod = 'RestPeriod',
  SoftBlocked = 'SoftBlocked',
  Barred = 'Barred',
  Disconnected = 'Disconnected',
  NotActive = 'NotActive',
  StolenBlocked = 'StolenBlocked',
  StolenPending = 'StolenPending',
  SoftBlockPending = 'SoftBlockPending',
  BlockPending = 'BlockPending',
  ActivationPending = 'ActivationPending',
  UnblockPending = 'UnblockPending',
  ReadyForActivation = 'ReadyForActivation',
  Unknown = 'Unknown',
}

export enum SimStatusCodes {
  OpenAndActive = 0,
  InUse = 1,
  Reserved = 2,
  RestPeriod = 3,
  SoftBlocked = 8,
  Barred = 9,
  Disconnected = 10,
  NotActive = 20,
  StolenBlocked = 99,
  StolenPending = 100,
  SoftBlockPending = 101,
  BlockPending = 102,
  ActivationPending = 103,
  UnblockPending = 104,
  ReadyForActivation = 105,
  Unknown = 999, // No simCard object on subscription
}

export enum SimOrderStatus {
  Unknown = 'Unknown',
  Success = 'Success',
  AlreadyInProgress = 'AlreadyInProgress',
  SimCardNotInValidStatus = 'SimCardNotInValidStatus',
  SubscriptionIsNotInContext = 'SubscriptionIsNotInContext',
  SubscriptionIsNotActive = 'SubscriptionIsNotActive',
  CustomerIsInDunningProcess = 'CustomerIsInDunningProcess', // When user hasn't payed bills
  FeeCannotBeCoveredFromBillingGroupBalance = 'FeeCannotBeCoveredFromBillingGroupBalance',
}

export enum EsimActivationStatusCode {
  Available = 'Available',
  Reserved = 'Reserved',
  Downloaded = 'Downloaded',
  Enabled = 'Enabled',
  Deleted = 'Deleted',
  Disabled = 'Disabled',
  Released = 'Released',
  Unknown = 'Unknown',
}

export type SimCardStatusKeys = keyof typeof SimStatus;

export enum SimCardType {
  Combi = 'Combi',
  Nano = 'Nano',
  Standard = 'Standard',
  Esim = 'Esim',
  Unknown = 'Unknown',
}

export enum BlurbType {
  Subscription = 'subscription',
  SimCard = 'simCard',
  EsimCard = 'eSimCard',
}

export enum SpecialActivateBlockCase {
  /* when status is a combination of 99 and 105 */
  '99_105' = '99_105',
}

export type SimCard = {
  id: string;
  icc: string;
  status: SimStatus;
  statusCode: number;
  pin1: string;
  pin2: string;
  puk1: string;
  puk2: string;
  type: SimCardType;
  isActive: boolean;
};

export type ESimCard = {
  uiccid: 'string';
  esimDownloadLink: 'string';
  status: EsimActivationStatusCode;
  qrImageData: {
    base64Data: 'string';
    mediaType: 'string';
  };
};

export type SelfManagedDataLimit = {
  enabled: boolean;
  limit: number;
  calculatedDataUsed: number;
  calculatedDataRemaining: number;
};

export enum SurfPassCountryReference {
  Europe = 'DATA_ROAMING_PASS_EUROPA',
  NorthAmerica = 'DATA_ROAMING_PASS_NORTH_AMERICA',
}

export type SurfPassDataPackage = {
  validTo: string;
  dataRemaining: number;
};

export type SurfPass = SurfPassDataPackage & {
  countryReference: SurfPassCountryReference;
  surfPassDataPackages: SurfPassDataPackage[];
};

export type Subscription = {
  alias: string;
  connectHardwareEligabilityStatus?: ConnectHardwareEligabilityStatus;
  discountVouchers?: DiscountVoucher[];
  futureCancellationAllowed: boolean;
  id: string;
  isAllowedAsParentSubscription?: boolean;
  isExtraSimOrphan?: boolean;
  isInBindingPeriod: boolean;
  name: string;
  numberPort?: NumberPort;
  packageId?: string;
  parentId?: string;
  phoneNumber: string;
  selfManagedDataLimit?: SelfManagedDataLimit;
  serviceId?: string;
  simCards: SimCard[];
  startDate: string;
  status: SubscriptionStatus;
  statusCode: SubscriptionStatusCode;
  subscriptionSuspension?: SubscriptionSuspension;
  surfPasses: SurfPass[];
  type: SubscriptionType;
  usage?: Usage;
};

export type ProductOptions = {
  allowChangeSatelliteRoamingBlocked: boolean;
  belongsToSubscriptionWithId: string;
  internationalCallsServicesBlocked: boolean;
  satelliteRoamingBlocked: boolean;
  voicemailActivated: boolean;
};

export type FriendVoucherType = {
  keyword: string;
};

export type SurfPassCmsData = {
  $type: string;
  description: string;
  expirationDate: string;
  lineItem: string;
  reference: string;
  remainingShared: string;
  remainingSurf: string;
  surfPassName: string;
  id: number;
  name: string;
  url: string;
};

export type ConfirmationModal = {
  $type: string;
  body: string;
  buttonText: string;
  header: string;
  reference: string;
};

export type AbonnemangCmsData = {
  $type: string;
  actionsChangeSubscriptionButtonText: string;
  actionsExtraSurfButtonText: string;
  actionsRefillNonContractButtonText: string;
  confirmationModals: BlockListItem<ConfirmationModal>[];
  friendGetFriendVoucherBody: string;
  friendGetFriendVoucherHeader: string;
  friendGetFriendVoucherLink: Link | null;
  internationalInfoEuInfoLink: Link;
  internationalInfoEuNoSurfInfo: string;
  internationalInfoEuSurfHeader: string;
  internationalInfoEuSurfInfo: string;
  internationalInfoSurfPasses: SurfPassCmsData[];
  pageAppHeader: string;
  subscriptionInformationHasPlanHasData: string;
  subscriptionInformationHasPlanNoData: string;
  subscriptionInformationNoPlanHasData: string;
  subscriptionInformationNoPlanNoDataBroadband: string;
  subscriptionInformationNoPlanNoDataVoice: string;
  subscriptionInformationSurfRefillInfo: string;
  subscriptionInformationSurfRefillInfoEu: string;
  surfInfoExtraUserDescription: string;
  surfInfoExtraUserDescriptionWithUsers: string;
  surfInfoExtraUserHeader: string;
  surfInfoExtraUserHeaderWithUsers: string;
  surfInfoExtraUserOrphanInfoBody: string;
  surfInfoExtraUserOrphanInfoHeader: string;
  surfInfoNoncontract: string;
  surfInfoPausedHeader: string;
  surfInfoRemainingSharedSurf: string;
  surfInfoRemainingSurf: string;
  surfInfoPausedCostText: string;
  surfInfoPausedNoCostText: string;
  id: number;
  name: string;
  url: string;
};

export type MappedUsage = {
  isUnlimited: boolean;
  total: number;
  remaining: number;
  euLimitRemaining: number;
  hasEuLimitBeenConsumed: boolean;
  euLimitTotal: number;
  currentPackageDataTotal: number;
};
