import { ReactNode } from 'react';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';

import { Account, AccountUserRole, RatorAccount, Brand, LoginMethod, PaymentInfo } from './account';
import { Cart, LineItem, LineItemInput } from './checkout';

export * from './account';
export * from './adyen';
export * from './apiResponse';
export * from './authenticateBankId';
export * from './cancelSubscription';
export * from './checkout';
export * from './error';
export * from './featureFlags';
export * from './icon';
export * from './invoice';
export * from './login';
export * from './numberPort';
export * from './package';
export * from './payment';
export * from './product';
export * from './subscription';
export * from './topup';

export * from './cms/aktiveraesimCmsData';
export * from './cms/bestallsimCmsData';
export * from './cms/bindAbonnemangCmsData';
export * from './cms/buyMobileSubscriptionCmsData';
export * from './cms/cancelSubscriptionCmsData';
export * from './cms/cardpaymentCmsData';
export * from './cms/changeSubscriptionCmsData';
export * from './cms/convertExtraUserModalCmsData';
export * from './cms/extraUsersCmsData';
export * from './cms/featureFlagCmsData';
export * from './cms/generalCmsData';
export * from './cms/hardwareCmsData';
export * from './cms/internationalPricesPage';
export * from './cms/loginCmsData';
export * from './cms/myPagesCmsData';
export * from './cms/pausSubscriptionCmsData';
export * from './cms/paymentCmsData';
export * from './cms/proposalCmsData';
export * from './cms/selectMainSubscriptionModalCmsData';
export * from './cms/settingsCmsData';
export * from './cms/startPageCmsData';
export * from './cms/surfLimitComponentCmsData';
export * from './cms/surfLimitModalCmsData';

export type AbonnemangsdetaljJsonType = typeof import('@/pages/api/cms/abonnemangsdetalj.json');
export type AddsurfJsonType = typeof import('@/pages/api/cms/addsurf.json');
export type BaseJsonType = typeof import('@/pages/api/cms/base.json');
export type BetalningarJsonType = typeof import('@/pages/api/cms/betalningar.json');
export type InternationalPriceListJsonType = typeof import('@/pages/api/cms/internationalpricelist.json');
export type InternationalPricesB2BJsonType = typeof import('@/pages/api/cms/international-prices-b2b.json');
export type InternationalPricesB2CJsonType = typeof import('@/pages/api/cms/international-prices-b2c.json');
export type KontoinstallningarJsonType = typeof import('@/pages/api/cms/kontoinstallningar.json');
export type OfferJsonType = typeof import('@/pages/api/cms/offers.json');

export type HallonAuthUser = {
  id: string;
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_at: number;
};

export const ACCESS_TOKEN_ROLE_KEY = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export type DecodedAccessToken = {
  nbf: number;
  exp: number;
  iss: string;
  aud: string[];
  client_id: string;
  sub: string;
  auth_time: number;
  idp: string;
  [ACCESS_TOKEN_ROLE_KEY]: AccountUserRole | AccountUserRole[];
  rator_accounts: string[] | string;
  jti: string;
  sid: string;
  iat: number;
  scope: string[];
  amr: LoginMethod[];
};

/**  Use to create a new type from e.g. keys from an enum
 *  enum TestEnum = {
    A: "A",
    B: "B",
    C: "C"
 }
 *  PickKey<typeof TestEnum, "A" | "B">
 */
export type PickKey<Type, Keys extends keyof Type> = Extract<keyof Type, Keys>;

export type ApiResponseError = {
  data: {
    message: string;
    errors: Record<string, unknown>;
    status: number;
    type: 'error';
  };
};

export type HallonContext = {
  account: Account | null;
  setAccount: (account: Account) => void;
  hasActiveCart: boolean;
  userId: string | null;
  userEmail: string | null;
  loginMethod: LoginMethod | null;
  accounts?: RatorAccount[];
  isApp: boolean;
  pageBrand: Brand;
  roles: AccountUserRole[];
};

export type BlockItemProps = Pick<HallonContext, 'userId'>;

export type CurrentUserPaymentInfo = PaymentInfo & {
  hasCardExpired: boolean;
  willCardExpire: boolean;
};

export type Checkout = {
  cart: Cart | null;
  availablePhonenumbers: string[] | null;
  selectedPhonenumbers: LineItemInput[];
  voiceLineItems: LineItem[] | null;
};

export type Notice = {
  header?: string;
  description?: ReactNode;
  text?: ReactNode;
  preset?: NoticeFieldPreset;
  children?: React.ReactElement;
  buttonText?: string;
  buttonOnClick?: () => void;
  buttonHref?: string;
  reference?: string;
};

export type AppLayoutContextType = {
  top: number;
  right: number;
  bottom: number;
  left: number;
  height: number;
};

export type PageTrackingProps = {
  salesAgent?: string | null;
  salesChannel?: string | null;
  cmsContextPageId?: number | null;
};
